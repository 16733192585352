type Nullable<T> = T | null;

type FunctionVoid = () => void;

type StringNullable = Nullable<string>;

enum Color {
  DARK = 'dark',
  WHITE = 'white'
}

export {
  Color,
  type FunctionVoid,
  type Nullable,
  type StringNullable,
};
