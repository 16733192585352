const isTouchDevice = () => {
  if (typeof window !== 'undefined') {
    return window.matchMedia('(pointer: coarse)').matches;
  }

  return false;
};

const compareSortByCondition = <T extends object>(
  a: T,
  b: T,
  condition: (typeToCheck: T) => boolean,
) => {
  if (condition(a) && !condition(b)) {
    return -1;
  }
  if (!condition(a) && condition(b)) {
    return 1;
  }
  return 0;
};

/**
 * returns the input value if it is an array,
 * otherwise wraps the entire input value with an array.
 * @param value
 */
const ensureArray = <T>(value: T | T[]) => ([] as T[]).concat(value ?? []);

/**
 * from an array of subArrays, find the common items that are included everywhere
 * @param itemArray
 */
const findCommon = <T>(itemArray: T[][]) => {
  if (!itemArray || itemArray.length === 0) {
    return [];
  }

  return itemArray
    .reduce((acc, array) => acc.filter((item) => Array.isArray(array) && array.includes(item)));
};

const removeSubItemFromArrayByValue = <T>(mainArray: T[], itemsToRemove: T[]) => {
  itemsToRemove.forEach((item) => {
    const index = mainArray
      .findIndex((alreadyToggled) => alreadyToggled === item);

    if (index > -1) {
      mainArray.splice(index, 1);
    }
  });
  return mainArray;
};

export {
  compareSortByCondition,
  ensureArray,
  findCommon,
  isTouchDevice,
  removeSubItemFromArrayByValue,
};
